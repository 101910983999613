import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { ImageMetadata } from '../../../../models/src/Photos/ImageMetadata';
import { IUserState } from '../../@types/UserState';
import { useDeletePhotoMutation } from '../../features/Admin/photosAdminSlice';
import { useGetRootQuery } from '../../features/root/rootApiSlice';

const PhotoSearchResult: FC<{ photos: Array<ImageMetadata>, userState: IUserState }> = ({ photos, userState }) => {

    const classes = useStyles();
    const { data: siteHost = undefined } = useGetRootQuery();
    const [deletePhotoMutation] = useDeletePhotoMutation();

    const [adjustedPhotos, setAdjustedPhotos] = useState([] as Array<ImageMetadata>);

    useEffect(() => {
        if (siteHost !== undefined) {
            const photosCopy = JSON.parse(JSON.stringify(photos)) as Array<ImageMetadata>;
            for (const photo of photosCopy) {
                photo.sizes.sort((a, b) => a.width - b.width);
            }
            setAdjustedPhotos(photosCopy);
        }
    }, [siteHost, photos])

    /*

    <img
     srcset="https://events-www.dev.photosbyfmork.com/photos/20211101-132052-8411-320.jpg 320w,https://events-www.dev.photosbyfmork.com/photos/20211101-132052-8411-720.jpg 720w,https://events-www.dev.photosbyfmork.com/photos/20211101-132052-8411-1080.jpg 1080w,https://events-www.dev.photosbyfmork.com/photos/20211101-132052-8411-1440.jpg 1440w" 
     sizes="(min-width: 1200px) 530px, (min-width: 992px) 440px, (min-width: 768px) 320px, (min-width: 576px) 230px, 90vw" 
     alt="20211101-132052-8411" 
     title="20211101-132052-8411"
      loading="lazy" 
     style="opacity: 1; transition: opacity 700ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;">
    */

    function getSrcSet(photo: ImageMetadata): string {
        const srcSetElements = photo.sizes.map(x => `https://${siteHost}/photos/${photo.id}-${x.width}.jpg ${x.width}w`);
        // srcSetElements.push('90vw');
        return srcSetElements.join(', ');
    }


    async function deletePhoto(input: ImageMetadata): Promise<void> {
        await deletePhotoMutation([input.id, userState]);
        setTimeout(() => {
            const index = adjustedPhotos.findIndex(x => x.id === input.id);
            if (index >= 0) {
                adjustedPhotos.splice(index, 1);
            }
        }, 500)
    }


    return (

        adjustedPhotos.length > 0
            ? <Grid container>
                {
                    adjustedPhotos.map(x =>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            lg={3}
                            xl={2}
                            key={x.id}
                            className={classes.root}>
                            <Box
                                className={classes.imageLink}>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    lg={12}
                                    xl={12}>
                                    <Grid
                                        item
                                        className={classes.imageToolboxContainer}
                                        xs={12}
                                        sm={12}
                                        lg={12}
                                        xl={12}>
                                        <a href='#' onClick={() => deletePhoto(x)}><DeleteForeverIcon /></a>
                                    </Grid>
                                    <img

                                        className={classes.image}
                                        srcSet={getSrcSet(x)}
                                        sizes="(min-width: 1200px) 530px, (min-width: 992px) 440px, (min-width: 768px) 320px, (min-width: 576px) 230px, 90vw"
                                        alt={x.id}
                                        title={x.id}
                                        loading='lazy'

                                    // Add a padding-bottom value so that the images can be layed out before they are loaded
                                    // style={{ paddingBottom: paddingBottom }}
                                    // onLoad={onImageLoad}
                                    />

                                </Grid>

                            </Box>

                        </Grid>)
                }
            </Grid>
            : <>No photos</>


    )
}

const useStyles = makeStyles((theme: Theme) => ({
    uploader: {
        margin: '0.3em',
        padding: '0.3em',
        border: '1px solid #555',
    },
    image: {
        position: "absolute",
        // position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        minHeight: "auto", //auto
        minWidth: "auto", //auto
        maxWidth: "100%",
        maxHeight: "100%",
        //objectFit: "cover",

        padding: '1rem',

    },
    imageLink: {
        paddingBottom: "100%",
        display: "block",
        position: "relative",
        overflow: "hidden",
        left: 0,
        top: 0,
        border: "1px solid #000",
    },
    imageToolboxIcon: {
        marginLeft: '0.2rem',
        marginRight: '0.2rem',
        marginTop: '0.2rem',
    },
    imageToolboxContainer: {
        backgroundColor: '#00000099',
        position: 'absolute',
        left: "50%",
        top: "100%",
        transform: "translate(-50%, -100%)",
        zIndex: 1000,
        opacity: 0.5,
        //        padding: '0.2rem',
    },
    root: {
        padding: theme.spacing(2),
        width: '100%',
        height: '100%',
        position: "relative",
        // width: 120,
        // "&::before": {
        //     display: "block",
        //     // content: "''",
        //     paddingBottom: "100%"
        // }

    }
}))

export default PhotoSearchResult

