import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExternalShoppingCart } from '../../DataInterfaces';
import { RootState } from '../../state/store';


export interface VisitorState {
    identifier: string;
};


const initialState: VisitorState = {
    identifier: ''
};

export const visitorSlice = createSlice({
    name: 'visitor',
    initialState,
    reducers: {
        setVisitor: (state, action: PayloadAction<VisitorState>) => {
            state.identifier = action.payload.identifier;
        }
    }
});


export const selectVisitor = (state: RootState) => state.visitor;

export const { setVisitor } = visitorSlice.actions;