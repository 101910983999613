import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Configuration } from '../../Configuration';


export const rootApiSlice = createApi({
    reducerPath: 'rootApi',
    tagTypes: ['root'],
    baseQuery: fetchBaseQuery({
        baseUrl: Configuration.ApiUrl,
    }),
    endpoints: (builder) => ({


        getRoot: builder.query<string, void>({
            query: () => '/',
            providesTags: [{ type: 'root', id: 'root' }]
        })
    })
});



export const {
    useGetRootQuery
} = rootApiSlice;
