import { FC, useEffect } from 'react';
import PhotosEditor from './PhotosEditor';

const PhotosAdminView: FC<any> = () => {

    console.info('Loading PhotosAdminView');

    useEffect(() => {
        document.title = `f/mork - Photos administration`;
        window.scrollTo(0, 0);
    }, []);

    return (
        <PhotosEditor />
    )
}

export default PhotosAdminView;