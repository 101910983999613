import { FC, useState } from 'react'
import { ExternalPhotoPrintProduct } from '../DataInterfaces'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from '../state/hooks';
import {
    useAddProductMutation,
    useGetPrintProductsQuery
} from '../features/shop/shopApiSlice';
import { selectVisitor } from '../features/visitor/visitorSlice';

const BuyPrintPart: FC<{ imageId: string }> = ({ imageId }) => {
    const classes = useStyles();
    const visitor = useAppSelector(selectVisitor);
    const [addProduct] = useAddProductMutation();

    const [skipLoadingPrintProducts, setSkipLoadingPrintProducts] = useState(true);

    const { data = [] } = useGetPrintProductsQuery({
        imageId: imageId,
    }, {
        skip: skipLoadingPrintProducts
    });

    const [selectedProductCode, setSelectedProductCode] = useState<string>('');
    // const [hasLoaded, setHasLoaded] = useState<boolean>(false);


    const handleProductSelected = (event: SelectChangeEvent<unknown>) => {
        setSelectedProductCode(event.target.value as any as string);

    }

    const loadShop = () => {
        setSkipLoadingPrintProducts(false);
    }

    const addSelectedItemToCart = () => {
        addProduct({
            visitorId: visitor.identifier,
            productCode: selectedProductCode
        });
    }



    const printSelectItem = (printProduct: ExternalPhotoPrintProduct) => {
        return (
            <MenuItem
                key={printProduct.productCode}
                value={printProduct.productCode}>
                {printProduct.product.sizeInCentimeters.width}x{printProduct.product.sizeInCentimeters.height} cm{!printProduct.isExactUncroppedSize ? "*" : ""} - SEK {printProduct.product.priceInSek}
            </MenuItem>
        );
    }

    const activeShopPart = () => {
        return (
            <>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 220 }} >
                    <InputLabel id="demo-simple-select-standard-label">Select print size</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectedProductCode}
                        onChange={handleProductSelected}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>

                        {data.map((x) => {
                            return printSelectItem(x)
                        })}
                    </Select>
                </FormControl>
                <Button onClick={addSelectedItemToCart} variant="outlined" >Add to cart</Button>
            </>
        );
    }


    const inactiveShopPart = () => {
        return (
            <>
                <Button onClick={loadShop} variant="outlined" >Buy print</Button>
            </>
        );
    }


    return (
        <Grid className={classes.shopForPrintContainer} container>
            {
                data.length > 0 ? activeShopPart() : inactiveShopPart()
            }
        </Grid >
    )



}


const useStyles = makeStyles(() => ({
    shopForPrintContainer: {
        marginTop: '1rem'
    }
}))


export default BuyPrintPart;