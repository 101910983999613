import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { FC, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import { DisplayImageData } from '../DataInterfaces';
import { isFeatureActive } from '../featureFlags';
import { useGetImageQuery } from '../features/images/imagesApiSlice';
import BuyPrintPart from './BuyPrintPart';
import ExifDetailView from './Elements/ExifDetailView';
import ImageKeywords from './Elements/ImageKeywords';
import PageIsLoading from './PageIsLoading';

const SingleImageView: FC<{}> = () => {
    const classes = useStyles()
    const params = useParams();
    const imageId = params.imageId as string;
    const { data = {} as DisplayImageData, error: getImageQueryrror } = useGetImageQuery({ imageId: imageId });

    const printShopEnabled = isFeatureActive('printShop');

    function setImageTitle(imageData: DisplayImageData) {
        const imageHasTitle = imageData !== undefined
            && imageData.title !== undefined && imageData.title !== 'undefined'
            && (imageData?.title?.length > 0 ?? false);

        if (imageHasTitle) {
            document.title = `f/mork - ${imageData.title} (${imageData.id})`;
        } else {
            document.title = `f/mork - ${imageData.id} `;
        }
    }


    useEffect(() => {

        document.title = `f/mork - ${imageId}`;
        console.info(data);
        if (data) {
            setImageTitle(data);
        }
        window.scrollTo(0, 0);
    }, [data, imageId]);


    if (data.src) {
        return (
            <>
                <Helmet
                    meta={[
                        {
                            property: "og:url",
                            content: `https://www.photosbyfmork.com/image/${imageId}`
                        },
                        {
                            property: "og:image",
                            content: data.src
                        },
                        {
                            property: "og:image:type",
                            content: "image/jpeg"
                        },
                    ]}
                />
                <Grid
                    container
                    alignItems='stretch'
                >
                    <Grid
                        item
                        sm={8}
                        className={classes.displayImageContainer}
                    >

                        <img
                            key={imageId}
                            className={classes.galleryDisplayImage}
                            // src={data.src}
                            srcSet={data.srcSet}
                            // sizes="(min-width: 1200px) 530px, (min-width: 992px) 440px, (min-width: 768px) 320px, (min-width: 576px) 230px, 90vw"
                            alt={data.title ?? data.id}
                            title={data.title ?? data.id} />
                    </Grid>
                    <Grid
                        item
                        sm={4}
                    >
                        <div
                            className={classes.imageInfo}
                        >
                            <Typography
                                variant={'h2'}
                            >
                                {data.title ?? data.id}
                            </Typography>


                            <div
                                className={classes.displayImageId}
                            >
                                ID: {data.id}
                            </div>

                            <ImageKeywords keywords={data.keywords} />

                            <ExifDetailView exifDetails={data.exifDetails} />

                            {printShopEnabled ? (
                                <div>
                                    <BuyPrintPart imageId={imageId} />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Grid>
                </Grid >
            </>
        )
    } else if (getImageQueryrror !== null && getImageQueryrror !== undefined) {
        return <>Image not found</>
    } else {
        return <PageIsLoading />
    }

};



const useStyles = makeStyles((theme: Theme) => ({
    galleryDisplayImage: {
        border: '1px solid',
        borderColor: theme.palette.divider,
        maxHeight: 'calc(100vh - 140px)',
        maxWidth: '100%',
        // marginRight: '1rem',
        height: 'auto',

        // float: 'left'

    },
    displayImageContainer: {
        textAlign: 'center',
    },
    displayImageId: {
        color: 'inherit'
    },
    imageInfo: {
        maxWidth: '100%',
        paddingLeft: '1rem',

    }
}))


export default SingleImageView;
