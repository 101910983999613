import * as React from 'react';
import { Cookies } from 'react-cookie';
import { useJwt } from 'react-jwt';
import { IAuthToken, IUserState, UserStateContextType } from '../@types/UserState';

export const UserStateContext = React.createContext<UserStateContextType | null>(null);

const UserStateProvider: React.FC<React.ReactNode> = ({ children }) => {

    const cookies = new Cookies();

    const authCookie = cookies.get('auth');

    const { decodedToken, isExpired } = useJwt<IAuthToken>(authCookie);


    const timeToExpiration = new Date(decodedToken?.expires ?? 0).valueOf() - new Date().valueOf();
    console.info(`timeToExpiration: ${timeToExpiration / 1000} seconds`);


    const expectedState: IUserState = {
        authToken: authCookie ?? '',
        isAuthenticated: timeToExpiration > 0
    };

    const [userState, setUserState] = React.useState<IUserState>(
        {
            authToken: authCookie ?? '',
            isAuthenticated: timeToExpiration > 0
        }
    );

    if (userState.authToken !== expectedState.authToken || userState.isAuthenticated !== expectedState.isAuthenticated) {
        setUserState(expectedState);
    }

    const updateUserState = (state: IUserState) => {
        // console.info(`Updating user state (isAuthenticated: ${state.isAuthenticated})`);
        // if (timeToExpiration < 0) {
        //     console.info('Session has expired');
        //     state.isAuthenticated = false;
        // }
        // setUserState(state);
    }
    console.info(`userState (in context): ${JSON.stringify(userState)}`);

    return <UserStateContext.Provider value={{ userState, updateUserState }}>{children}</UserStateContext.Provider>;

}

export default UserStateProvider;
