import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ExternalPhotoPrintProduct, ExternalShoppingCart } from '../../DataInterfaces'

export const shopApiSlice = createApi({
    reducerPath: 'shopApi',
    tagTypes: ['cart'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders(headers) {
            return headers;
        }
    }),
    endpoints: (builder) => ({

        // query for getting shopping cart
        getShoppingCart: builder.query<ExternalShoppingCart, string | void>({
            query: (visitorId: string) => `/shop/cart/${visitorId}`,
            providesTags: (result) => [{ type: 'cart', id: 'main' }]
        }),


        getPrintProducts: builder.query<ExternalPhotoPrintProduct[], { imageId: string }>({
            query: (input) => `/prints/${input.imageId}`,
        }),

        // mutation for adding product to cart
        addProduct: builder.mutation<ExternalShoppingCart, { visitorId: string, productCode: string }>({
            query: (arg) => {
                const { visitorId, productCode } = arg;
                return {
                    url: `/shop/cart/${visitorId}/products/${productCode}`,
                    method: 'post'
                };
            },
            invalidatesTags: [{ type: 'cart', id: 'main' }],
        }),

        // mutation for changing the quantity of a product in the cart
        changeProductQuantity: builder.mutation<ExternalShoppingCart, { visitorId: string, cartItemId: string, delta: number }>({
            query: (arg) => {
                const { visitorId, cartItemId, delta } = arg;
                return {
                    url: `/shop/cart/${visitorId}/item/${cartItemId}/quantity/${delta}`,
                    method: 'post'
                }
            },
            invalidatesTags: [{ type: 'cart', id: 'main' }],
        })
    })

})

export const {
    useGetShoppingCartQuery,
    useGetPrintProductsQuery,
    useAddProductMutation,
    useChangeProductQuantityMutation
} = shopApiSlice;