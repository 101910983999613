import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import KeywordLink from '../KeywordLink';

import { KeywordsByLetter } from '../../DataInterfaces';

const LetterWithKeywords: FC<{ keywordsByLetter: KeywordsByLetter, allKeywordsByLetter: KeywordsByLetter[] }> = ({ keywordsByLetter, allKeywordsByLetter }) => {

    const classes = useStyles()
    const theme = useTheme();
    const isMediumToLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));


    return (
        <Grid className={classes.keywordsContainer} container >
            <Grid id={`${keywordsByLetter.letter}`} className={classes.lettersContainer} item sm={12}>
                <Typography align='center' >
                    {
                        isMediumToLargeScreen
                            ? allKeywordsByLetter.map(y => keywordsByLetter.letter === y.letter
                                ? (<strong key={`keywordletter-${y.letter}`} className={classes.current_keyword_letter}>{y.letter}</strong>)
                                : (<a className={classes.keyword_letter} href={`#${y.letter}`}>{y.letter}</a>)
                            )
                            : (<strong className={classes.keyword_letter}>{keywordsByLetter.letter}</strong>)
                    }
                </Typography>
            </Grid>

            {keywordsByLetter.keywords.map(y => {
                return (
                    <Grid key={`keywordlink-${y.keyword}`} item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <KeywordLink keyword={y.keyword} count={y.count} />
                    </Grid>
                )
            })}

        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    keyword_letter: {
        padding: '0.3em'
    },
    current_keyword_letter: {
        padding: '0.3em',
        border: '1px solid #fff',
        fontSize: '120%'
    },
    lettersContainer: {
        backgroundColor: '#555',
        '& a': {
            color: '#ccc'
        },
        width: '100%'
    },
    keywordsContainer: {
        marginBottom: '2em',

    }
}))

export default LetterWithKeywords