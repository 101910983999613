import Grid from '@mui/material/Grid';
import { FC, useEffect, useState } from 'react';
import { KeywordModel } from '../../../models/src/Photos/KeywordModel';
import { KeywordsByLetter } from '../DataInterfaces';
import { useGetKeywordsQuery } from '../features/images/imagesApiSlice';
import LetterWithKeywords from './keywords/LetterWithKeywords';
import PageIsLoading from './PageIsLoading';

const KeywordsView: FC<any> = () => {

    const { data = [] } = useGetKeywordsQuery();

    const [keywordsByLetter, setKeywordsByLetter] = useState<Array<KeywordsByLetter>>([]);
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);

    const unpackKeywordData = (data: Array<KeywordModel>) => {
        var result: Array<KeywordsByLetter> = [];
        var current: KeywordsByLetter = {
            letter: '',
            keywords: []
        };
        const arrayToSort = new Array<KeywordModel>(...data);

        const sortedData = arrayToSort.sort((a, b) => {
            console.info(`sort a = ${JSON.stringify(a)}, b = ${JSON.stringify(b)}`);
            const first = a.key.toLowerCase();
            const second = b.key.toLowerCase();
            console.info(`Comparing '${first}' and '${second}'`);
            return first.localeCompare(second, 'sv-SE');
        });

        for (const keyword of sortedData) {

            // Get the first letter of the next keyword
            const firstLetter = keyword.key.substring(0, 1).toUpperCase();

            // If the letter is different from the previous one...
            if (current.letter.toUpperCase() !== firstLetter) {

                // If the previous letter was not empty, push the current data
                // to the result array
                if (current.letter !== '') {
                    result.push(current);

                }

                // Initialize a new, empty 'current' element
                current = {
                    letter: firstLetter,
                    keywords: []
                };
            }

            // Push this keyword into the current group
            current.keywords.push({
                count: keyword.count,
                keyword: keyword.displayText
            });
        }

        result.push(current);
        return result;
    }

    useEffect(() => {



        var result: Array<KeywordsByLetter> = unpackKeywordData(data);

        setKeywordsByLetter(result);
        setHasLoaded(true);

        window.scrollTo(0, 0);
    }, [data]);

    document.title = 'f/mork - Keywords';

    if (hasLoaded) {
        return (

            <Grid container alignItems='stretch'>
                {
                    keywordsByLetter
                        .map(x =>
                            <LetterWithKeywords
                                key={`lwk-${x.letter}`}
                                keywordsByLetter={x}
                                allKeywordsByLetter={keywordsByLetter}
                            />
                        )
                }
            </Grid>

        )
    } else {
        return <PageIsLoading />
    }
}



export default KeywordsView;