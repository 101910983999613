import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { shoppingCartSlice } from '../features/shop/shoppingCartSlice';
import { visitorSlice } from '../features/visitor/visitorSlice';

import { adminSlice } from '../features/Admin/adminSlice';
import { photosAdminSlice } from '../features/Admin/photosAdminSlice';
import { eventsApiSlice } from '../features/events/eventsApiSlice';
import { imagesApiSlice } from '../features/images/imagesApiSlice';
import { rootApiSlice } from '../features/root/rootApiSlice';
import { shopApiSlice } from '../features/shop/shopApiSlice';
// import counterReducer from '../features/counter/counterSlice';

export const store = configureStore({
    reducer: {
        shoppingCart: shoppingCartSlice.reducer,
        visitor: visitorSlice.reducer,
        [rootApiSlice.reducerPath]: rootApiSlice.reducer,
        [shopApiSlice.reducerPath]: shopApiSlice.reducer,
        [imagesApiSlice.reducerPath]: imagesApiSlice.reducer,
        [eventsApiSlice.reducerPath]: eventsApiSlice.reducer,
        [photosAdminSlice.reducerPath]: photosAdminSlice.reducer,
        [adminSlice.reducerPath]: adminSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(
            rootApiSlice.middleware,
            shopApiSlice.middleware,
            imagesApiSlice.middleware,
            eventsApiSlice.middleware,
            photosAdminSlice.middleware,
            adminSlice.middleware,
        );
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
