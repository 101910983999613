import { Grid, Input } from '@mui/material';
import React, { FC, FormEvent } from 'react';
import { UserStateContextType } from '../../@types/UserState';
import { UserStateContext } from '../../context/UserStateContext';
import { useCreateOneTimeSigninLinkMutation } from '../../features/Admin/adminSlice';

const SignIn: FC<any> = () => {

    const [createOneTimeSigninLink, { error: createOneTimeSigninLinkError }] = useCreateOneTimeSigninLinkMutation();


    const { userState } = React.useContext(UserStateContext) as UserStateContextType;

    const onSubmitSigninForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const target: HTMLFormElement = event.target as HTMLFormElement;
        const emailField = target.querySelector('#email-address') as HTMLInputElement;
        const email = emailField.value;
        const rr = await createOneTimeSigninLink(email);

        console.info(`storeEvent returned: ${JSON.stringify(rr)}`);
    }


    return (
        <Grid container>
            <Grid item>
                <form onSubmit={onSubmitSigninForm}>
                    <Input
                        id='email-address'
                        placeholder={'Email address'}
                    />

                    <button className="btn btn-danger" type="submit">Submit</button>
                </form>
                {
                    createOneTimeSigninLinkError !== undefined
                        ? <Grid container>
                            <Grid item>
                                {JSON.stringify(createOneTimeSigninLinkError)}
                            </Grid>
                        </Grid>
                        : <></>
                }
            </Grid>
        </Grid>
    )
}

export default SignIn;