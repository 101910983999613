import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from "@mui/material/Typography"
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { MenuProps } from '../AppMenu'


const CollapsedMenu: FC<MenuProps> = ({ appMenuItems, userIsAuthenticated }) => {
    const [anchorElNav, setAnchorElNav] = useState<EventTarget | any>(null);
    const classes = useStyles()

    const handleOpenNavMenu = (event: React.SyntheticEvent<EventTarget>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <>
            <IconButton
                size="large"
                aria-label="main menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                className={classes.root}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
            >
                {appMenuItems.filter(x => userIsAuthenticated || x.RequireAdmin === false).map((menuItem) => {
                    if (menuItem.link.startsWith("http")) {
                        return (
                            <a key={menuItem.name} href={menuItem.link}>
                                <MenuItem
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {menuItem.name}
                                </MenuItem>
                            </a>
                        )
                    } else {
                        return (
                            <MenuItem
                                key={menuItem.name}
                                onClick={handleCloseNavMenu}
                                component={RouterLink}
                                to={`${menuItem.link}`}
                            >
                                <Typography textAlign="center">{menuItem.name}</Typography>
                            </MenuItem>
                        )
                    }
                })}

            </Menu>
        </>
    )
}

const drawerWidth = 240

const useStyles = makeStyles(theme =>
    createStyles({
        appMenu: {
            width: '100%',
            minHeight: '30px',
            '& a': {
                textDecoration: 'none'
            },
            '& .MuiToolbar-root': {
                minHeight: '31px'
            },
            '& a:hover': {
                textDecoration: 'underline'
            }
        },
        navList: {
            width: drawerWidth,
        },
        menuItem: {
            width: drawerWidth
        },
        menuItemIcon: {
            color: 'inherit',
        },
        root: {

            '& .MuiPaper-root': {
                backgroundColor: '#333',
                color: '#fff'
            },
            '& .MuiPaper-root a': {
                textDecoration: 'none'
            }, '& .MuiPaper-root a:hover': {
                textDecoration: 'underline'
            }
        },
    }),
)

export default CollapsedMenu
