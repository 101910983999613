
export interface IFeatureFlag {
    name: string;
    isActive: boolean;
}



export const initFeatures = (...features: IFeatureFlag[]) => {
    localStorage.setItem('flags', JSON.stringify(features));
};

export const isFeatureActive = (featureName: string) => {
    const flagsData = process.env.REACT_APP_FEATURE_FLAGS; // localStorage.getItem('flags');
    // console.log(`Flags data: ${flagsData}`);
    if (flagsData) {
        const features: IFeatureFlag[] = JSON.parse(flagsData);
        const feature = features.find(x => x.name.toLowerCase() === featureName.toLowerCase());
        return feature && feature.isActive;
    }

    return false;
}
