import { FC } from 'react'
import { GalleryImage } from './GalleryImage'
import { ShowcaseImageData } from '../DataInterfaces';
import Typography from '@mui/material/Typography';
import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const ImageGallery: FC<{ images: ShowcaseImageData[] }> = ({ images }) => {

    const classes = useStyles();

    // const theme = useTheme();
    // const veryLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
    // const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    // const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    // const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    // // const verySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // const imageListCols = () => {
    //     if (veryLargeScreen) {
    //         return 5;
    //     }
    //     else if (largeScreen) {
    //         return 4;
    //     }
    //     else if (mediumScreen) {
    //         return 3;
    //     }
    //     else if (smallScreen) {
    //         return 2;
    //     }
    //     return 1;
    //     // { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }
    // }

    if (images.length > 0) {
        return (
            <Grid container>
                {
                    images.map((image, index) => {

                        return (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                                xl={3}
                                key={index}
                                className={classes.root}>
                                <GalleryImage
                                    key={image.imageId}
                                    image={image}
                                    loadLazy={true} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        )
    }
    else {
        return (
            <Typography align='center' component={'h2'} variant='h2'>(no images found)</Typography>
        )
    }
}

const useStyles = makeStyles((theme: Theme) => ({

    root: {
        padding: theme.spacing(2),
        width: '100%',
        height: '100%',
        position: "relative",
        // width: 120,
        // "&::before": {
        //     display: "block",
        //     // content: "''",
        //     paddingBottom: "100%"
        // }

    }

}))



export default ImageGallery