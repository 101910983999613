import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Configuration } from '../../Configuration';

export const adminSlice = createApi({
    reducerPath: 'adminApi',
    baseQuery: fetchBaseQuery({
        baseUrl: Configuration.ApiUrl,
    }),
    endpoints: (builder) => ({

        createOneTimeSigninLink: builder.mutation<any, string>(
            {
                query: (email) => ({
                    url: `/auth/onetimesignin/${email}`,
                    method: 'POST',
                    body: {},
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            }
        ),

        getAuthTokenFromOneTimeToken: builder.query<string, { oneTimeToken: string }>({
            query: (input) => `/auth/onetimesignin/${input.oneTimeToken}`,
            //providesTags: [{ type: 'events', id: 'all-events' }]
        }),
    })
});

export const {
    useCreateOneTimeSigninLinkMutation,
    useGetAuthTokenFromOneTimeTokenQuery
} = adminSlice;
