import { Semaphore } from "async-mutex";

interface ThrottlerConstructorParameters<TReturn> {
    maxParallelCount: number;
    promisesFactories: Array<() => Promise<TReturn>>;

}

export class Throttler<TReturn> {

    private readonly semaphore: Semaphore;

    constructor(private readonly params: ThrottlerConstructorParameters<TReturn>) {
        this.semaphore = new Semaphore(this.params.maxParallelCount);
    }

    public async resolve(): Promise<Array<Promise<TReturn>>> {
        const result: Array<Promise<TReturn>> = [];

        while (this.params.promisesFactories.length > 0) {
            // wait for semaphore
            const [semaphoreValue, releaseSemaphore] = await this.semaphore.acquire();

            // start the exection of the next promise
            const nextPromiseFactory = this.params.promisesFactories.shift();

            if (nextPromiseFactory !== undefined) {
                console.info('Invoking promise factory');
                const nextPromise = nextPromiseFactory();
                // add the promise to the result
                result.push(nextPromise);
                // make sure to release the semaphore
                nextPromise.then(
                    _ => releaseSemaphore(),
                    _ => releaseSemaphore()
                );
            } else {
                releaseSemaphore();
            }

        }

        return result;
    }

}


