import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ImageMetadata } from '../../../../models/src/Photos/ImageMetadata';
import { PresignedUpload } from '../../../../models/src/PresignedUpload';
import { IUserState } from '../../@types/UserState';
import { Configuration } from '../../Configuration';


export const photosAdminSlice = createApi({

    reducerPath: 'photosAdminApi',
    tagTypes: ['image', 'images'],
    baseQuery: fetchBaseQuery({
        baseUrl: Configuration.ApiUrl,
    }),
    endpoints: (builder) => ({


        getUploadUrl: builder.query<PresignedUpload, { filename: string, userState: IUserState }>({
            query: (input) => ({
                url: `/admin/photos/uploadurl/${input.filename}`,
                headers: {
                    Authorization: `Bearer ${input.userState.authToken}`
                }

            }),
            //providesTags: [{ type: 'events', id: 'all-events' }]
        }),

        findPhotos: builder.query<ImageMetadata[], { search: string, userState: IUserState }>({
            query: (input) => (
                {
                    url: `/admin/photos/${input.search}`,
                    headers: {
                        Authorization: `Bearer ${input.userState.authToken}`
                    }
                }
            )
        }),


        deletePhoto: builder.mutation<any, [string, IUserState]>(
            {
                query: ([photoId, userState]) => ({
                    url: `/admin/photos/${photoId}`,
                    method: 'delete',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        Authorization: `Bearer ${userState.authToken}`
                    },
                }),
                invalidatesTags: (result) => [{ type: 'image' }, { type: 'images' }]
            }
        ),


    })
});

export const {
    useGetUploadUrlQuery,
    useFindPhotosQuery,
    useDeletePhotoMutation
} = photosAdminSlice;
