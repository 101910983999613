import { Grid, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useEffect, useState } from 'react';
import { UserStateContextType } from '../../@types/UserState';
import { UserStateContext } from '../../context/UserStateContext';
import { HttpClient } from '../../lib/HttpClient';
import { FileUploadParameters, FileUploadState, UploadTask } from '../../lib/types/FileUploads';


const PhotoUploader: FC<{ uploadTask: UploadTask }> = ({ uploadTask }) => {

    const classes = useStyles();
    const httpClient = new HttpClient();
    const filename = uploadTask.file.name;
    const { userState } = React.useContext(UserStateContext) as UserStateContextType;

    // const { data: presignedUploadParameters = undefined, error: getUploadUrlQueryError } = useGetUploadUrlQuery({ filename: filename, userState: userState });
    const [presignedUploadParameters, setPresigneUploadParameters] = useState(undefined as unknown as FileUploadParameters);

    useEffect(() => {
        if (presignedUploadParameters !== undefined) {
            setUploadTaskState('initializing');
            startFileUpload(uploadTask, setUploadTaskState, httpClient, setUploadTaskProgress);
        }

    }, [presignedUploadParameters])

    useEffect(() => {

        uploadTask
            .getUploadParametersCallback(uploadTask.file.name)
            .then(uploadParameters => setPresigneUploadParameters(uploadParameters));

    }, [userState]);

    function setUploadTaskState(state: FileUploadState): void {

        console.info(`setUploadTaskState('${filename}', '${state}')`);

        if (uploadTask !== undefined) {
            uploadTask.state = state;
            console.info(`uploadTask.state = ${state}`);
        }
        else {
            console.warn(`No upload task found for file '${filename}'`);
        }

        // const temp: UploadTask = getUploadTaskCopy(uploadTask);
        // setuploadTask(temp);
        uploadTask.stateUpdatedCallback(uploadTask);

    }


    function setUploadTaskProgress(progress: number): void {

        if (uploadTask !== undefined) {
            uploadTask.progress = progress;
        }
        else {
            console.warn(`No upload task found for file '${filename}'`);
        }
        // const temp: UploadTask = getUploadTaskCopy(uploadTask);
        // console.info(`Setting task: ${JSON.stringify(temp)}`);
        // setuploadTask(temp);
        uploadTask.stateUpdatedCallback(uploadTask);
    }

    async function startFileUpload(
        task: UploadTask,
        setUploadTaskState: (state: FileUploadState) => void,
        httpClient: HttpClient,
        setUploadTaskProgress: (progress: number) => void): Promise<void> {

        if (presignedUploadParameters === undefined) {
            return;
        }

        try {
            console.info(`Will set ${task.file.name} to 'queued'`);
            setUploadTaskState('queued');

            const formdata = new FormData();

            for (const field of Object.keys(presignedUploadParameters.fields)) {
                formdata.append(field, presignedUploadParameters.fields[field]);
            }

            formdata.append('file', task.file.file);

            let latestProgressUpdate: Date = new Date((new Date().valueOf() - 1000));

            await httpClient.post(presignedUploadParameters.url, formdata, (event: ProgressEvent) => {
                if (task.state !== 'uploading') {
                    setUploadTaskState('uploading');
                }

                const now = new Date();
                if (now.valueOf() - latestProgressUpdate.valueOf() > 200) {
                    latestProgressUpdate = now;
                    const progress = Math.round(event.loaded / event.total * 100);
                    // console.info(`Upload progress: ${progress}`);
                    setUploadTaskProgress(progress);
                }

            });
            setUploadTaskProgress(100);
            setUploadTaskState('completed');
            // setTimeout(() => removeUploadTask(uploadTask.file.name), 2000);
        } catch (error) {
            setUploadTaskState('failed');
        }
    }




    return (

        uploadTask !== null && uploadTask !== undefined
            ? <Grid
                xs={12}
                sm={6}
                lg={3}
                xl={2}
                className={classes.uploaderContainer}
                key={uploadTask.file.name} item >
                <Grid container className={classes.uploader}
                >
                    <Grid item className={classes.uploadFilename}>
                        {uploadTask.file.name}
                    </Grid>

                    <Grid item className={classes.uploadState} >
                        ({uploadTask.state}) <LinearProgress
                            variant='determinate'
                            value={uploadTask.progress} />
                    </Grid>
                </Grid>
            </Grid >
            : <></>


    )
}

const useStyles = makeStyles(() => ({
    uploaderContainer: {
        padding: '1rem'
    },
    uploader: {
        // margin: '1em',
        // padding: '1em',
        // border: '1px solid #555',
    },
    uploadFilename: {
        display: 'block',
        width: '100%',
    },
    uploadState: {
        display: 'block',
        width: '100%'
    },
}))

export default PhotoUploader