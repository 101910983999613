import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { KeywordModel } from '../../../../models/src/Photos/KeywordModel';
import { Configuration } from '../../Configuration';
import { DisplayImageData, ShowcaseImageData } from '../../DataInterfaces';


export const imagesApiSlice = createApi({
    reducerPath: 'imagesApi',
    tagTypes: ['image', 'images'],
    baseQuery: fetchBaseQuery({
        baseUrl: Configuration.ApiUrl,
    }),
    endpoints: (builder) => ({


        getShowcaseImages: builder.query<ShowcaseImageData[], void>({
            query: () => '/image/showcase',
            providesTags: [{ type: 'images', id: 'showcase' }]
        }),


        getImage: builder.query<DisplayImageData, { imageId: string }>({
            query: (input) => `/image/${input.imageId}`,
            providesTags: (result) => [{ type: 'image', id: result?.id }]
        }),


        getKeywords: builder.query<KeywordModel[], void>({
            query: () => `/keyword`,
        }),


        getImagesByKeyword: builder.query<ShowcaseImageData[], { keyword: string }>({
            query: (input) => `/keyword/${input.keyword}`
        }),
    })
});



export const {
    useGetShowcaseImagesQuery,
    useGetImageQuery,
    useGetKeywordsQuery,
    useGetImagesByKeywordQuery
} = imagesApiSlice;
