import { Theme } from '@mui/material';
import Fade from '@mui/material/Fade';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ShowcaseImageData } from '../DataInterfaces';

const GalleryImage: FC<{ image: ShowcaseImageData, loadLazy: boolean }> = ({ image, loadLazy }) => {
    const classes = useStyles()
    const [imageLoaded, setImageLoaded] = useState(false)
    // Remove the padding-bottom property from the styles attribute when the image has been loaded.
    const onImageLoad = (event: React.SyntheticEvent<EventTarget>) => {
        setImageLoaded(true);
    }

    function getImageTitle() {
        return image.title === null || image.title === undefined || image.title === 'undefined'
            ? image.imageId
            : image.title;
    }

    // Calculate a padding-bottom value that can be used to lay out the
    // masonry grid prior to loading the images. This enables the images
    // to be lazy-loaded.
    // const paddingBottom = `${image.size.height / image.size.width * 100}%`;

    return <>
        <Link
            className={classes.imageLink}

            to={{
                pathname: `/image/${image.imageId}`
            }}
            state={{ image }}>
            <Fade
                in={imageLoaded}
                timeout={700}>
                <img
                    className={classes.image}
                    srcSet={image.srcSet}
                    sizes="(min-width: 1200px) 530px, (min-width: 992px) 440px, (min-width: 768px) 320px, (min-width: 576px) 230px, 90vw"
                    alt={image.title ?? image.imageId}
                    title={getImageTitle()}
                    loading='lazy'

                    // Add a padding-bottom value so that the images can be layed out before they are loaded
                    // style={{ paddingBottom: paddingBottom }}
                    onLoad={onImageLoad}
                />
            </Fade>
        </Link>
    </>
}

const useStyles = makeStyles((theme: Theme) => ({
    image: {
        position: "absolute",
        // position: "absolute",
        left: "50%",
        top: 0,
        minHeight: "100%", //auto
        minWidth: "100%", //auto
        // maxWidth:"100%"
        objectFit: "cover",

        transform: "translateX(-50%)",
        border: "1px solid #000",

    },
    imageLink: {
        paddingBottom: "100%",
        display: "block",
        position: "relative",
        overflow: "hidden",
        left: 0,
        top: 0,
    }

}))

export { GalleryImage };

// console.log(`${image.originalSize.height / image.originalSize.width}`);