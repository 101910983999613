import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExternalShoppingCart } from '../../DataInterfaces';
import { RootState } from '../../state/store';


export interface ShoppingCartState {
    cart: ExternalShoppingCart;
};

const initialState: ShoppingCartState = {
    cart: {} as ExternalShoppingCart
};


export const shoppingCartSlice = createSlice({
    name: 'shoppingCart',
    initialState,
    reducers: {
        cartUpdated: (state, action: PayloadAction<ExternalShoppingCart>) => {
            console.info(`Setting shopping cart: ${JSON.stringify(action.payload)}`);
            state.cart = action.payload;
        }
    },
    extraReducers: builder => {

    }
});


export const selectShoppingCart = (state: RootState) => state.shoppingCart;

export const { cartUpdated } = shoppingCartSlice.actions;