import { FC } from 'react'
import { Link } from 'react-router-dom';

const KeywordLink: FC<{ keyword: string, count?: number }> = ({ keyword, count }) => {
    return (
        <Link
            // key={keyword}
            to={{ pathname: `/keyword/${keyword}` }}
        >
            <>{keyword}{count ? ` (${count})` : ''}</>

        </Link>
    )
}

export default KeywordLink