import React, { FC, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import {
    Badge,
    Box,
    Button,
    Drawer,
    Grid,
    IconButton,
    Theme
} from '@mui/material';


import { selectVisitor } from '../features/visitor/visitorSlice'
import { useAppSelector } from '../state/hooks';
import { ExternalShoppingCart, ExternalShoppingCartItem } from '../DataInterfaces';
import { makeStyles } from '@mui/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { useGetShoppingCartQuery, useChangeProductQuantityMutation } from '../features/shop/shopApiSlice';

const MiniCart: FC<{}> = () => {
    const classes = useStyles();
    const [skipLoadingShoppingCart, setSkipLoadingShoppingCart] = React.useState(true);
    const visitor = useAppSelector(selectVisitor);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { data = {} as ExternalShoppingCart } = useGetShoppingCartQuery(
        visitor.identifier,
        {
            skip: skipLoadingShoppingCart,
        });

    const [changeProductQuantity] = useChangeProductQuantityMutation();

    useEffect(() => {
        if (visitor.identifier && visitor.identifier.length > 0) {
            setSkipLoadingShoppingCart(false);
        }
    }, [visitor])


    const handleOpenNavMenu = () => {
        setDrawerOpen(true);
    };


    const deleteItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const item: ExternalShoppingCartItem = (data.items.find(x => x.id === event.currentTarget.value)) as ExternalShoppingCartItem;
        if (item) {
            changeItemQuantity(event.currentTarget.value, -item.quantity);
        }
    }

    const decrementQuantity = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        changeItemQuantity(event.currentTarget.value, -1);
    }


    const incrementQuantity = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        changeItemQuantity(event.currentTarget.value, 1);
    }

    const changeItemQuantity = async (itemId: string, delta: number) => {
        changeProductQuantity({
            visitorId: visitor.identifier,
            cartItemId: itemId,
            delta: delta
        });
    }

    function hasCartAndItems(shoppingCart: ExternalShoppingCart) {
        return shoppingCart?.items?.length > 0 ?? false;
    }

    const shoppingCartButton = () => {

        const productCount = hasCartAndItems(data)
            ? getTotalProductCount(data)
            : 0;

        return (
            <Badge
                badgeContent={productCount}
                color='secondary'
            >
                <ShoppingCartIcon />
            </Badge>
        );

    }


    function getTotalProductCount(input: ExternalShoppingCart): number {
        return input.items
            .map(x => x.quantity)
            .reduce((a, b) => a + b);
    }


    const getShoppingCartTotal = () => {
        return data.items
            .map(x => x.quantity * x.printProduct.product.priceInSek)
            .reduce((a, b) => a + b);
    }



    const shoppingCartHeader = () => {
        return (
            <Grid
                container
                className={classes.miniCart}
            >
                <Typography variant={'h4'}>Shopping cart</Typography>
                <Button
                    variant='text'
                    onClick={() => setDrawerOpen(false)}
                >
                    <CloseIcon />
                </Button>
            </Grid>
        );
    }

    const shoppingCartFooter = () => {
        return hasCartAndItems(data)
            ? (
                <Grid container alignContent='center' className={classes.footer}>
                    <Grid item sm={12}>
                        Total: SEK {getShoppingCartTotal()}
                    </Grid>
                    <Grid item sm={12}>
                        <Button variant='text'>Go to shopping cart</Button>
                    </Grid>
                </Grid >
            ) : <></>;
    }

    const cartItem = (item: ExternalShoppingCartItem) => {
        return (
            <Grid key={item.id} container className={classes.miniCartItemContainer}>
                <Grid item >
                    <img alt={item.id} width='120px' height='auto' src={item.printProduct.image.src} />
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid itemRef=''>
                            {`${item.printProduct.product.sizeInCentimeters.width}x${item.printProduct.product.sizeInCentimeters.height} cm (SEK ${item.printProduct.product.priceInSek})`}
                        </Grid>

                        <Grid item>
                            <Button
                                variant='text'
                                onClick={decrementQuantity}
                                value={item.id}
                            >
                                <RemoveIcon />
                            </Button>
                            {item.quantity}
                            <Button
                                size='small'
                                variant='text'
                                onClick={incrementQuantity}
                                value={item.id}
                            >
                                <AddIcon />
                            </Button>
                        </Grid>


                        <Grid item>
                            <Button
                                variant='text'
                                onClick={deleteItem}
                                value={item.id}
                            >
                                <DeleteForeverIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const shoppingCartItems = () => {

        return hasCartAndItems(data)
            ? data?.items.map(x => cartItem(x))
            : <p>(empty)</p>;
    }

    const shoppingCartContent = () => {
        return (
            <Drawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                anchor='right'
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <Grid className={classes.miniCart} container>
                    {shoppingCartHeader()}
                    {shoppingCartItems()}
                    {shoppingCartFooter()}
                </Grid>
                {/* </Menu> */}
            </Drawer >
        );
    }

    return (
        <Box>
            <IconButton
                size="large"
                aria-label="shopping cart"
                aria-controls="menu-mini-shopping-cart"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
            >
                {shoppingCartButton()}
            </IconButton>
            {shoppingCartContent()}
        </Box>
    )
}


const useStyles = makeStyles((theme: Theme) => ({
    miniCart: {
        width: '310px'
    },
    miniCartItemContainer: {
        margin: '0.5rem',
        padding: '0.5rem',
        borderStyle: 'solid',
        borderWidth: '1px',
        width: '300px',
        borderColor: theme.palette.primary.light,

    },
    drawerPaper: {
        backgroundColor: '#333',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '400px'
        }

    },
    footer: {
        margin: '0.5rem',
        padding: '0.5rem',
    }
}))



export default MiniCart


