import Typography from '@mui/material/Typography';
import { FC, useEffect } from 'react'
import ImageGallery from './ImageGallery'
import PageIsLoading from './PageIsLoading'
import { useGetShowcaseImagesQuery } from '../features/images/imagesApiSlice'

const SiteIndex: FC<any> = () => {

    const { data = [] } = useGetShowcaseImagesQuery();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Typography align='center' component={'h1'} variant='h1'>Fredrik Mörk Photography</Typography>
            {
                data.length > 0
                    ? (<ImageGallery images={data} />)
                    : (<PageIsLoading />)
            }
        </>
    )

}

export default SiteIndex;