import React from "react";
import { Navigate } from "react-router-dom";
import { UserStateContextType } from "../../@types/UserState";
import { UserStateContext } from "../../context/UserStateContext";

const EnsureUserIsAuthenticated: React.FC<React.ReactNode> = ({ children }) => {
    const { userState } = React.useContext(UserStateContext) as UserStateContextType;

    // console.info(`EnsureUserIsAuthenticated: ${JSON.stringify(userState)}`);
    if (!userState.isAuthenticated) {
        Navigate({ to: '/auth/signin' });
    }


    return <>{children}</>

}

export default EnsureUserIsAuthenticated;
