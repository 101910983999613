import { Grid } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { UserStateContextType } from '../../@types/UserState';
import { UserStateContext } from '../../context/UserStateContext';
import { useGetAuthTokenFromOneTimeTokenQuery } from '../../features/Admin/adminSlice';

const OneTimeTokenView: FC<any> = () => {

    const params = useParams();
    const nav = useNavigate();
    const oneTimeToken = params.oneTimeToken as string;
    const { data: authToken = undefined, error: getAuthTokenError = undefined } = useGetAuthTokenFromOneTimeTokenQuery({ oneTimeToken: oneTimeToken });
    const { updateUserState } = React.useContext(UserStateContext) as UserStateContextType

    useEffect(() => {
        document.title = `f/mork - Signing in...`;
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (authToken !== undefined) {
            console.info(`Got auth token ${authToken.substring(0, 15)}[...]`);
            // updateUserState({
            //     isAuthenticated: true,
            //     authToken: authToken
            // });
            const cookies = new Cookies();
            cookies.set('auth', authToken, {
                expires: new Date((new Date()).valueOf() + 60 * 60 * 1000), // expires in one hour
                path: '/'
            });
            nav({
                pathname: '/'
            });
        }
    }, [authToken, updateUserState]);

    return (
        getAuthTokenError !== undefined
            ? <Grid container>
                <Grid item>
                    {JSON.stringify(getAuthTokenError)}
                </Grid>
            </Grid>
            : authToken !== undefined
                ? <>You are authenticated</>
                : <>You are not authenticated</>
    )
}

export default OneTimeTokenView;