import { isFeatureActive } from "./featureFlags";

type Feature = 'printShop' | 'eventsPhotoShop';
export class Configuration {
    public static readonly ApiUrl = Configuration.trimEnd(process.env.REACT_APP_API_BASE_URL, '/');

    private static trimEnd(input: string | undefined, chars: string): string | undefined {
        if (input !== null && input !== undefined) {
            while (input.endsWith(chars)) {
                input = input.substring(0, input.length - chars.length);
            }
        }
        return input;
    }

    public static isFeatureEnabled(feature: Feature): boolean {
        const result = isFeatureActive(feature);
        return result === undefined ? false : result;
    }


}
