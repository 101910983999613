import React, { FC } from 'react';
import KeywordLink from '../KeywordLink';

const ImageKeywords: FC<{ keywords: Array<string> }> = ({ keywords }) => {

    return (
        <>
            {
                keywords ?
                    keywords.map((x, i) =>
                        <React.Fragment
                            key={`${x}fragment`}
                        >
                            <KeywordLink keyword={x} />
                            {i < keywords.length - 1 ? ', ' : ''}
                        </React.Fragment>
                    ) : <></>}
        </>
    )
}

export default ImageKeywords
