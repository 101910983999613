import axios, { Axios, AxiosRequestConfig } from "axios";

export class HttpClient {
    public async get(uri: string, headers?: Record<string, string>): Promise<string> {
        console.info(`[HttpClient] GET '${uri}' - headers: ${headers}`);
        const config: AxiosRequestConfig<void> = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            transitional: axios.defaults.transitional,
            adapter: axios.defaults.adapter,
            transformRequest: axios.defaults.transformRequest,
            transformResponse: axios.defaults.transformResponse,
            xsrfCookieName: axios.defaults.xsrfCookieName,
            xsrfHeaderName: axios.defaults.xsrfHeaderName,
            validateStatus: axios.defaults.validateStatus
        };

        if (headers !== null && headers !== undefined && config.headers !== undefined) {
            for (const key in headers) {
                console.info(`${key} = ${headers[key]}`)
                config.headers[key] = headers[key];
            }
        }

        const axiosClient = new Axios(config);
        const response = await axiosClient.get(uri);
        if (!response.status.toString().startsWith('2')) {
            console.error(`HTTP response ${response.status}: ${JSON.stringify(response.data)}`);
        }
        return JSON.stringify(response.data);
    }

    public async post(
        uri: string,
        formdata: FormData,
        progressCallback: (progressEvent: any) => void) {
        console.info(`[HttpClient] POST '${uri}'`);
        const config: AxiosRequestConfig<void> = {
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // },
            transitional: axios.defaults.transitional,
            adapter: axios.defaults.adapter,
            transformRequest: axios.defaults.transformRequest,
            transformResponse: axios.defaults.transformResponse,
            xsrfCookieName: axios.defaults.xsrfCookieName,
            xsrfHeaderName: axios.defaults.xsrfHeaderName,
            validateStatus: axios.defaults.validateStatus
        };
        const axiosClient = new Axios(config);
        const response = await axiosClient.post(uri, formdata, {
            onUploadProgress: progressCallback
        });

        if (!response.status.toString().startsWith('2')) {
            console.error(`HTTP response ${response.status}: ${JSON.stringify(response.data)}`);
        }

    }
}
