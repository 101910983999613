import { FC, useContext, useEffect, useState } from 'react'

import IconHome from '@mui/icons-material/Home'
import IconPhotoLibrary from '@mui/icons-material/PhotoLibrary'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from "@mui/material/Typography"
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Link as RouterLink } from 'react-router-dom'

import MiniCart from '../shop/minicart'

import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { UserStateContextType } from '../@types/UserState'
import { UserStateContext } from '../context/UserStateContext'
import { isFeatureActive } from '../featureFlags'
import CollapsedMenu from './Menu/CollapsedMenu'
import MenuBar from './Menu/MenuBar'

interface AppMenuItem {
    name: string;
    link: string;
    RequireAdmin: boolean;
    Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
    };
}

export interface MenuProps {
    appMenuItems: Array<AppMenuItem>
    userIsAuthenticated: boolean
}

const appMenuItemDefinitions: Array<AppMenuItem> = [
    {
        name: 'Home',
        link: '/',
        Icon: IconHome,
        RequireAdmin: false
    },
    {
        name: 'Landscape',
        link: '/keyword/landscape',
        Icon: IconPhotoLibrary,
        RequireAdmin: false
    },
    {
        name: 'Forest',
        link: '/keyword/forest',
        Icon: IconPhotoLibrary,
        RequireAdmin: false
    },
    {
        name: 'Black & White',
        link: '/keyword/black & white',
        Icon: IconPhotoLibrary,
        RequireAdmin: false
    },
    {
        name: 'Animals',
        link: '/keyword/animals',
        Icon: IconPhotoLibrary,
        RequireAdmin: false
    },
    {
        name: 'All keywords',
        link: '/keyword',
        RequireAdmin: false
    },
    {
        name: 'About',
        link: '/about',
        RequireAdmin: false
    },
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/photosbyfmork',
        Icon: InstagramIcon,
        RequireAdmin: false
    },
    {
        name: 'Facebook',
        link: 'https://www.facebook.com/photosbyfmork',
        Icon: FacebookIcon,
        RequireAdmin: false
    },
    {
        name: 'Photos admin',
        link: '/admin/photos',
        RequireAdmin: true
    },
    {
        name: 'Events admin',
        link: '/admin/events',
        RequireAdmin: true
    },
]

const AppMenu: FC = () => {

    const printShopEnabled = isFeatureActive('printShop');

    const { userState } = useContext(UserStateContext) as UserStateContextType;
    const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

    const [appMenuItems, setAppMenuItems] = useState([] as Array<AppMenuItem>);

    const classes = useStyles()

    useEffect(() => {
        setAppMenuItems(appMenuItemDefinitions.filter(x => userIsAuthenticated || x.RequireAdmin === false));
    }, [userIsAuthenticated]);

    useEffect(() => {
        const isAuthenticated = userState !== null && userState !== undefined && userState.isAuthenticated;
        setUserIsAuthenticated(isAuthenticated);
    }, [userState]);

    return (
        <AppBar className={classes.appMenu} position='sticky'>
            <Toolbar >

                { /* small screen menu */}
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
                    <CollapsedMenu appMenuItems={appMenuItems} userIsAuthenticated={userIsAuthenticated} />
                </Box>

                { /* logo */}
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ mr: 2, flexGrow: 1 }}
                >
                    <RouterLink to={'/'}>f/mork</RouterLink>
                </Typography>

                { /* large screen menu */}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
                    <MenuBar appMenuItems={appMenuItems} userIsAuthenticated={userIsAuthenticated} />
                </Box>
                {printShopEnabled ? (<MiniCart />) : (<></>)}
            </Toolbar>
        </AppBar>
    );
};

const drawerWidth = 240

const useStyles = makeStyles(() =>
    createStyles({
        appMenu: {
            width: '100%',
            minHeight: '30px',
            '& a': {
                textDecoration: 'none'
            },
            '& .MuiToolbar-root': {
                minHeight: '31px'
            },
            '& a:hover': {
                textDecoration: 'underline'
            }
        },
        navList: {
            width: drawerWidth,
        },
        menuItem: {
            width: drawerWidth
        },
        menuItemIcon: {
            color: 'inherit',
        },
        root: {

            '& .MuiPaper-root': {
                backgroundColor: '#333',
                color: '#fff'
            },
            '& .MuiPaper-root a': {
                textDecoration: 'none'
            }, '& .MuiPaper-root a:hover': {
                textDecoration: 'underline'
            }
        },
    }),
)

export default AppMenu
