import { FC } from 'react'

import Button from '@mui/material/Button'
import { Link as RouterLink } from 'react-router-dom'


import { MenuProps } from "../AppMenu"

const MenuBar: FC<MenuProps> = ({ appMenuItems, userIsAuthenticated }) => {

    return (
        <>
            {appMenuItems.filter(x => userIsAuthenticated || x.RequireAdmin === false).map((menuItem) => {
                if (menuItem.link.startsWith("http")) {
                    return (
                        <a key={menuItem.name} href={menuItem.link}>
                            <Button

                                // to='/'
                                // onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {menuItem.name}
                            </Button>
                        </a>
                    )
                } else {
                    return (
                        <Button
                            key={menuItem.name}
                            component={RouterLink}
                            to={`${menuItem.link}`}
                            // onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            {menuItem.name}
                        </Button>
                    )
                }

            })}
        </>
    )
}

export default MenuBar
