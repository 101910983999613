import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import ImageGallery from './ImageGallery'
import PageIsLoading from './PageIsLoading'
import Typography from '@mui/material/Typography';
import { useGetImagesByKeywordQuery } from '../features/images/imagesApiSlice';

const KeywordImagesView: FC<any> = () => {

    let params = useParams();

    let keyword = params.keyword as string;
    const { data = [] } = useGetImagesByKeywordQuery({ keyword: keyword });

    useEffect(() => {
        document.title = `f/mork - '${keyword}' images`;

        window.scrollTo(0, 0);

    }, [keyword]);


    if (data.length > 0) {
        return (
            <>
                <Typography align='center' component={'h1'} variant='h1'>{keyword}</Typography>
                <ImageGallery images={data} />
            </>
        )
    } else {
        return (
            <>
                <Typography align='center' component={'h1'} variant='h1'>{keyword}</Typography>
                <PageIsLoading />
            </>
        )
    }
}

export default KeywordImagesView;