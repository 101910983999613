import { createTheme, CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as process from 'process';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
import { Route, Routes, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AboutView from './components/AboutView';
import EnsureUserIsAuthenticated from './components/Admin/EnsureUserIsAuthenticated';
import PhotosAdminView from './components/Admin/PhotosAdminView';
import AppMenu from './components/AppMenu';
import OneTimeTokenView from './components/Authentication/OneTimeTokenView';
import SignIn from './components/Authentication/signin';
import KeywordImagesView from './components/KeywordImagesView';
import KeywordsView from './components/KeywordsView';
import SingleImageView from './components/SingleImageView';
import SiteIndex from './components/SiteIndex';
import UserStateProvider from './context/UserStateContext';
import { setVisitor } from './features/visitor/visitorSlice';
import { useAppDispatch } from './state/hooks';

window['process'] = process;

const lightTheme = createTheme({
    typography: {
        fontFamily: [
            'Alegreya sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'
        ].join(',')

    },
    palette: {
        primary: {
            main: '#999',
        },
        // secondary: {
        //     main: '#111'
        // },
        background: {
            default: '#333'
        },
        text: {
            primary: '#fff',
            secondary: '#999'
        }
        // // mainSiteBackgroundColor: '#fff',
        // // mainDarkAccentColor: '#111',
        // // mainlightaccentcolor: '#ccc',
        // // mainBorderColor: '#999',
        // // mainLinkColor: '#07f',
        // // navbarBackgroundColor: '#ddd',
        // // navLinkHighlight: '#000',
    },
    components: {
        MuiButton: {
            defaultProps: {
                style: {
                    marginRight: '1rem'
                }
            }
        }
    }
});


lightTheme.typography.body1.fontSize = 20;

const App: React.FC = () => {

    console.info(`API url: ${process.env.REACT_APP_API_BASE_URL}`);

    const [cookies, setCookie] = useCookies();

    const dispatch = useAppDispatch();
    const [analyticsEnabled, setAnalyticsEnabled] = useState<boolean>(false);
    const classes = useStyles()
    const location = useLocation();

    const localSetVisitor = () => {
        // Get visitor identifier, either from cookie or generate a new one
        let visitorIdentifier = cookies['v']
            ? cookies['v']
            : uuidv4();

        // Set cookie expiry date
        let expires = new Date();
        expires.setDate(new Date().getDate() + 30);

        // Set the cookie
        setCookie(
            'v',
            visitorIdentifier,
            {
                path: '/',
                expires: expires,
                sameSite: 'lax'
            });

        dispatch(setVisitor({ identifier: visitorIdentifier }));
    }

    useEffect(() => {

        localSetVisitor();

    }, []);


    useEffect(() => {
        const cookieConsent = cookies['cookieConsent'] as string ?? 'false';
        setAnalyticsEnabled(cookieConsent.toLowerCase() === 'true');
    }, [cookies])



    useEffect(() => {
        if (analyticsEnabled) {
            ReactGA.initialize('UA-182717-7');
        }
    }, [analyticsEnabled]);

    useEffect(() => {
        if (analyticsEnabled) {
            const page = window.location.pathname + window.location.search;
            if (!window.location.href.includes("localhost")) {
                ReactGA.pageview(page);
            }
        }

    }, [location])


    return (
        <ThemeProvider theme={lightTheme}>
            <UserStateProvider>
                <CssBaseline />

                <AppMenu />
                <main className={classes.container}>
                    <Routes>
                        <Route path="/">
                            <Route
                                index
                                element={<SiteIndex />}
                            />

                            <Route path='auth'>
                                <Route
                                    path='onetimetoken/:oneTimeToken'
                                    element={<OneTimeTokenView />}
                                />
                                <Route
                                    path='signin'
                                    element={<SignIn />}
                                />
                            </Route>


                            <Route path='admin'>

                                <Route
                                    path="photos"
                                    element={<EnsureUserIsAuthenticated><PhotosAdminView /></EnsureUserIsAuthenticated>}
                                />

                            </Route>


                            <Route
                                path="keyword"
                                element={<KeywordsView />}
                            />
                            <Route
                                path="keyword/:keyword"
                                element={<KeywordImagesView />}
                            />
                            <Route
                                path="image/:imageId"
                                element={<SingleImageView />}
                            />
                            <Route
                                path="about"
                                element={<AboutView />}
                            />
                        </Route>
                    </Routes>
                </main>
                <CookieConsent
                    location='bottom'
                    buttonText='Accept all'
                    declineButtonText='Accept necessary only'
                    cookieName='cookieConsent'
                    onAccept={() => setAnalyticsEnabled(true)}
                    onDecline={() => setAnalyticsEnabled(false)}
                    enableDeclineButton
                    flipButtons
                >
                    <p>This site uses cookies. The necessary cookies are used for making the site work properly.</p>
                    <p>The optional cookies are for Google Analytics, which helps me understand how the site is used. In either case, I don't get information about who you are.</p>
                </CookieConsent>
                <footer>Copyright © Fredrik Mörk</footer>
            </UserStateProvider>
        </ThemeProvider>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: '1rem',
        '& a': {
            textDecoration: 'none'
        },
        '& h1': {
            fontSize: '3rem',
            fontWeight: 100
        },

        '& h2': {
            fontSize: '2rem',
            fontWeight: 100
        }

    }
}))
export default App


