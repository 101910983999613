import { FC, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import Grid from '@mui/material/Grid'

const AboutView: FC<{}> = () => {

    const classes = useStyles()

    useEffect(() => {
        console.log("scrolling up");
        window.scrollTo(0, 0);
    });

    return (
        <Grid container >
            <Grid item lg={2} xl={3}></Grid>
            <Grid item lg={8} xl={6}>
                <h1>About Me</h1>
                <img className={classes.portraitImage}
                    src="https://www.gravatar.com/avatar/f40ccff29d3ac083ca1cb511d8824b2f?s=180&amp;d=identicon&amp;r=PG" alt=""
                    width="180" height="180" />
                <p>
                    Even though I earn my income mainly from software development, photography has been with me for a longer
                    time than software development.
                </p>
                <p>
                    Photography is a process that is active pretty much all the time, regardless of whether I
                    carry a camera or not. But it's more visible for people around me if I do have a camera. When I suddenly
                    stop, walk
                    back a few steps. Lean down, squint a bit. Then maybe just stand up again, catch up. Or, at other times,
                    there is
                    indeed an image there to be made.
                </p>
                <p>
                    When exposed to this behavior, a colleague of mine once said
                    <em>"wow, it must in fact be quite interesting being a photographer, seeing the world like that"</em>.
                </p>
                <p>
                    Yes, I think it is.
                </p>
                <h2>Contact</h2>
                <p>
                    If you want to find out more about me, my main hub on the Internet is at <a href="http://fmork.net">fmork.net</a>. You
                    can also follow me on <a href="https://instagram.com/photosbyfmork">Instagram</a> or on
                    my <a href="https://www.facebook.com/photosbyfmork/">Facebook page</a>.
                </p>
                <p>If you want to get in touch with me directly, send a mail to <a
                    href="mailto:contact@photosbyfmork.com">contact@photosbyfmork.com</a></p>
                <h2>The gear</h2>
                <p>
                    Yes, I know: it's not about the gear. Or... well, it's not <em>only</em> about the gear, at least. You can't
                    make great images with gear alone, without the creative process of "seeing the image". But good gear is a
                    help. So if you are interested in the technical part, here is what I use:
                </p>
                <h3>Cameras</h3>
                <ul>
                    <li><strong>Canon EOS R6</strong> - this is my main camera</li>
                    <li><strong>Canon EOS 5D Mark III</strong> - backup/second camera</li>
                </ul>
                <h3>Lenses</h3>
                <ul>
                    <li><strong>Canon EF 24-105mm f/4L IS USM</strong> - My main lens for landscapes and allround photography
                    </li>
                    <li><strong>Canon EF 50mm f/1.4 USM</strong> - I use this mostly for portraits</li>
                    <li><strong>Canon EF 100mm f/2.8 Macro</strong> - For closeups and macro</li>
                    <li><strong>Canon EF 300mm f/4L IS USM</strong> - Mostly for wildlife, but also for some closeup work such
                        as flowers and larger insects (thanks to its excellent close focusing distance)</li>
                    <li><strong>Canon EF 500mm f/4L IS USM</strong> - For wildlife</li>
                </ul>
            </Grid>
            <Grid item lg={2} xl={3}></Grid>
        </Grid>

    )
}

const useStyles = makeStyles(theme =>
    createStyles({
        portraitImage: {
            display: 'inline',
            float: 'right'
        }
    }),
)


export default AboutView