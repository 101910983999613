import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, Input, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from 'react';
import { UserStateContextType } from '../../@types/UserState';
import { Configuration } from '../../Configuration';
import { UserStateContext } from '../../context/UserStateContext';
import { useFindPhotosQuery } from '../../features/Admin/photosAdminSlice';
import { HttpClient } from '../../lib/HttpClient';
import { FileUploadParameters, LocalFile, UploadTask } from '../../lib/types/FileUploads';
import { Throttler } from '../../Throttler';
import PhotoSearchResult from './PhotoSearchResult';
import PhotoUploader from './PhotoUploader';

const PhotosEditor: FC<any> = () => {
    const classes = useStyles();

    const ref = useRef(null);
    const httpClient = new HttpClient();
    const [runningUploadTasks, setRunningUploadTasks] = useState([] as UploadTask[]);

    const { userState } = React.useContext(UserStateContext) as UserStateContextType;
    const [searchString, setSearchString] = useState('');

    const [queuedFiles, setQueuedFiles] = useState([] as Array<LocalFile>);
    const [completedFiles, setCompletedFiles] = useState([] as Array<LocalFile>);
    const [failedFiles, setFailedFiles] = useState([] as Array<LocalFile>);
    const [overallUploadProgress, setOverallUploadProgress] = useState(0);

    const { data: photosFromApi = [] } = useFindPhotosQuery({ search: searchString, userState: userState });


    const onSubmitFileUpload = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const target: HTMLFormElement = event.target as HTMLFormElement;

        const fileElement = target.querySelector("#files") as HTMLInputElement;

        setQueuedFiles([]);
        setCompletedFiles([]);
        setFailedFiles([]);


        const promiseFactories: Array<() => Promise<void>> = [];

        if (fileElement !== null && fileElement.files !== null) {

            for (let index = 0; index < fileElement.files.length; index++) {



                const element = fileElement.files[index];
                console.info(`file: ${element.name}, type: ${element.type}`)
                const localFile: LocalFile = {
                    file: element,
                    name: element.name,
                    type: element.type,
                    size: element.size
                };


                const promiseFactory = () => {
                    const executor = (resolve: () => void, reject: (reason?: any) => void): void => {

                        // remove item from queued files array
                        const index = queuedFiles.findIndex(x => x.name === localFile.name);
                        if (index >= 0) {
                            queuedFiles.splice(index, 1);
                            setQueuedFiles(queuedFiles);
                        }
                        console.info(`Running executor for file ${localFile.name}`);
                        const uploadTask: UploadTask = {
                            file: localFile,
                            progress: 0,
                            state: "pending",
                            stateUpdatedCallback: task => {
                                uploadTaskStateUploadedCallback(task);
                                if (task.state === 'completed') {
                                    resolve();
                                    completedFiles.push(localFile);
                                    setCompletedFiles(completedFiles);
                                } else if (task.state === 'failed') {
                                    reject(`Upload of file ${localFile.name} failed`);
                                    failedFiles.push(localFile);
                                    setFailedFiles(failedFiles);
                                }
                            },
                            getUploadParametersCallback: getUploadParameters
                        };

                        runningUploadTasks.push(uploadTask);
                        updateUploadTasks();
                    }

                    return new Promise<void>(executor);
                }

                promiseFactories.push(promiseFactory);
                queuedFiles.push(localFile);
                setQueuedFiles(queuedFiles);
            }

        }

        const throttler = new Throttler({
            maxParallelCount: 3,
            promisesFactories: promiseFactories
        });

        console.info('Invoking throttler...');
        try {
            await Promise.all(await throttler.resolve());
        } catch (error) {

            console.error(`Throttler threw error: ${error}`);
        }

    }


    async function getUploadParameters(filename: string): Promise<FileUploadParameters> {
        const uploadParametersAsString = await httpClient.get(`${Configuration.ApiUrl}/admin/photos/uploadurl/${filename}`, {
            Authorization: `Bearer ${userState.authToken}`
        });

        return JSON.parse(uploadParametersAsString);

    }

    useEffect(() => {

        const total = queuedFiles.length + runningUploadTasks.length + completedFiles.length + failedFiles.length;
        const progress = ((completedFiles.length + failedFiles.length) / total) * 100;
        setOverallUploadProgress(progress);
    }, [queuedFiles, runningUploadTasks, completedFiles, failedFiles])

    function updateUploadTasks() {
        const temp: UploadTask[] = [];
        for (const c of runningUploadTasks) {
            temp.push(c);
        }
        setRunningUploadTasks(temp);

        if (ref.current !== null) {
            const f: HTMLInputElement = ref.current;
            f.value = '';
        }
    }

    useEffect(() => {
        const el2 = ref.current;
        console.log(el2);
    }, []);


    function uploadTaskStateUploadedCallback(task: UploadTask): void {
        const localTaskIndex: number = findLocalTaskIndex(task);
        if (localTaskIndex < 0) {
            console.info(`localTask not found for '${task.file.name}'`);
        } else {
            runningUploadTasks[localTaskIndex] = task;

            if (task.state === 'completed') {
                console.info(`Task for file ${runningUploadTasks[localTaskIndex].file.name} got completed.`);

                const n: number = findLocalTaskIndex(task);
                if (n >= 0) {
                    console.info(`Removing task for file ${runningUploadTasks[n].file.name}.`);
                    runningUploadTasks.splice(n, 1);
                }
            }
            updateUploadTasks();

        }
    }

    function findLocalTaskIndex(task: UploadTask): number {
        return runningUploadTasks.findIndex(x => x.file.name === task.file.name);
    }


    let searchDelayHandle: NodeJS.Timeout | undefined = undefined;

    const onSearchInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        console.info(`Search change: ${event.target.value}`);
        if (searchDelayHandle !== undefined) {
            clearTimeout(searchDelayHandle);
        }
        const searchValue = event.target.value;
        searchDelayHandle = setTimeout(() => {
            setSearchString(searchValue);
        }, 500);
    }

    return (
        <>
            <h1>Manage photos</h1>

            <Grid container>
                <Grid item>
                    <Input
                        placeholder='Search for photos'
                        onChange={onSearchInputChange}
                        startAdornment={<SearchIcon />}
                    />
                </Grid>
            </Grid>
            <PhotoSearchResult photos={photosFromApi} userState={userState} />
            <h1>Upload photos</h1>
            <Grid container>
                <Grid item>
                    <form onSubmit={onSubmitFileUpload}>
                        <input
                            ref={ref}
                            id='files'
                            type={'file'}
                            multiple
                        />
                        <Button
                            className="btn btn-primary"
                            variant='contained'
                            type="submit"
                        >
                            Upload files
                        </Button>
                    </form>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>Queued: {queuedFiles.length}, ongoing: {runningUploadTasks.length}, completed: {completedFiles.length}</Grid>
            </Grid>
            <Grid container>
                <Grid item className={classes.overallProgressContainer}><LinearProgress variant='determinate' value={overallUploadProgress} /></Grid>
            </Grid>
            <Grid container>
                {
                    runningUploadTasks.sort((a, b) => {
                        if (a.state === b.state) {
                            return 0;
                        }

                        if (a.state === 'completed') {
                            return -1;
                        }

                        if (b.state === 'completed') {
                            return 1;
                        }

                        if (a.state === 'uploading') {
                            return -1;
                        }

                        if (b.state === 'uploading') {
                            return 1;
                        }

                        return 0;
                    }).map(x => <PhotoUploader key={x.file.name} uploadTask={x} />)
                }
            </Grid></>
    )
}

const useStyles = makeStyles(theme => ({
    overallProgressContainer: {
        height: '1rem',
        width: '100%'
    }
}))

export default PhotosEditor

