import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PhotoEvent } from "../../../../models/src/Events/PhotoEvent";
import { PhotoEventPricelist } from '../../../../models/src/Events/PhotoEventOrder';
import { PhotoEventPhoto } from '../../../../models/src/Events/PhotoEventPhoto';
import { PhotoEventWithPhotosAndPricelist } from '../../../../models/src/Events/PhotoEventWithPhotos';
import { IUserState } from '../../@types/UserState';
import { Configuration } from '../../Configuration';


export const eventsApiSlice = createApi({
    reducerPath: 'eventsApi',
    tagTypes: [
        'event',
        'eventwithphotos',
        'events',
        'pricelists'
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: Configuration.ApiUrl,
    }),
    endpoints: (builder) => ({


        getAllEvents: builder.query<PhotoEvent[], void>({
            query: () => '/events',
            providesTags: [{ type: 'events', id: 'all-events' }]
        }),


        getAllEventsForAdmin: builder.query<PhotoEvent[], IUserState>({
            query: (userState) => ({
                url: '/admin/events',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userState.authToken}`
                }
            }),
            providesTags: [{ type: 'events', id: 'all-events' }]
        }),

        getAllPricelists: builder.query<PhotoEventPricelist[], IUserState>({
            query: (userState) => ({
                url: '/admin/pricelists',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${userState.authToken}`
                }
            }),
            providesTags: [{ type: 'pricelists', id: 'all-pricelists' }]
        }),

        getSingleEvent: builder.query<PhotoEvent, { eventId: string }>({
            query: (input) => `/events/${input.eventId}`,
            providesTags: (result) => [{ type: 'events', id: result?.id }]
        }),

        getSingleEventWithPhotos: builder.query<PhotoEventWithPhotosAndPricelist, { eventId: string }>({
            query: (input) => `/events/${input.eventId}/full`,
            providesTags: (result) => [{ type: 'eventwithphotos', id: result?.event.id }]
        }),

        getPhotoDownloadUrl: builder.query<string, { eventId: string, imageId: string }>({
            query: (input) => `/events/${input.eventId}/images/${input.imageId}/download`
        }),

        storeSingleEvent: builder.mutation<any, [PhotoEvent, IUserState]>(
            {
                query: ([photoEvent, userState]) => ({
                    url: '/admin/events',
                    method: 'POST',
                    body: photoEvent,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        Authorization: `Bearer ${userState.authToken}`
                    },
                }),
                invalidatesTags: ['events']
            }
        ),

        deleteSingleEventPhoto: builder.mutation<any, [PhotoEventPhoto, IUserState]>(
            {
                query: ([photoEventPhoto, userState]) => ({
                    url: `/admin/events/${photoEventPhoto.eventId}/images/${photoEventPhoto.id}`,
                    method: 'DELETE',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        Authorization: `Bearer ${userState.authToken}`
                    },
                }),
                invalidatesTags: ['events']
            }
        ),

        deleteSingleEvent: builder.mutation<any, [string, IUserState]>(
            {
                query: ([eventId, userState]) => ({
                    url: `/admin/events/${eventId}`,
                    method: 'DELETE',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        Authorization: `Bearer ${userState.authToken}`
                    },
                }),
                invalidatesTags: ['events']
            }
        ),


        storePricelist: builder.mutation<any, [PhotoEventPricelist, IUserState]>(
            {
                query: ([photoEvent, userState]) => ({
                    url: '/admin/pricelists',
                    method: 'POST',
                    body: photoEvent,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        Authorization: `Bearer ${userState.authToken}`
                    },
                }),
                invalidatesTags: ['pricelists']
            }
        ),

    })
});



export const {
    useGetAllEventsQuery,
    useGetAllEventsForAdminQuery,
    useGetSingleEventQuery,
    useGetSingleEventWithPhotosQuery,
    useGetAllPricelistsQuery,
    useLazyGetPhotoDownloadUrlQuery,
    useStoreSingleEventMutation,
    useStorePricelistMutation,
    useDeleteSingleEventMutation,
    useDeleteSingleEventPhotoMutation

} = eventsApiSlice;
