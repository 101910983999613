import CameraAltIcon from '@mui/icons-material/CameraAlt';
import TuneIcon from '@mui/icons-material/Tune';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { ExifDetails } from '../../DataInterfaces';

const ExifDetailView: FC<{ exifDetails: ExifDetails }> = ({ exifDetails }) => {

    const classes = useStyles();

    return (
        <>
            <div className={classes.technical_details}>
                <CameraAltIcon /> {exifDetails.camera}, {exifDetails.lensSpecification}
            </div>
            <div className={classes.technical_details}>
                <TuneIcon /> {exifDetails.exposureDetails}
            </div>
        </>
    )
}


const useStyles = makeStyles((theme: Theme) => ({
    technical_details: {
        color: '#888',
        '& svg': {
            verticalAlign: 'bottom'
        }
    },
}))

export default ExifDetailView